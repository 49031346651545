[data-color-mode="light"][data-light-theme="light"],
[data-color-mode="light"][data-light-theme="light"] ::backdrop,
[data-color-mode="auto"][data-light-theme="light"],
[data-color-mode="auto"][data-light-theme="light"] ::backdrop {
  --bgColor-success-emphasis: #1f883d;
  --button-danger-bgColor-active: #8b0820;
  --button-outline-bgColor-active: #0757ba;
  --button-primary-bgColor-active: #197935;
  --button-primary-bgColor-disabled: #95d8a6;
  --button-primary-bgColor-hover: #1c8139;
  --buttonCounter-danger-fgColor-rest: #c21c2c;
  --color-ansi-cyan: #1b7c83;
  --color-ansi-cyan-bright: #3192aa;
  --control-checked-bgColor-active: #0757ba;
  --control-checked-bgColor-hover: #0860ca;
  --fgColor-danger: #d1242f;
  --reactionButton-selected-bgColor-hover: #caecff;
  --avatarStack-fade-bgColor-default: #c8d1da;
  --avatarStack-fade-bgColor-muted: #dae0e7;
  --bgColor-accent-emphasis: #0969da;
  --bgColor-accent-muted: #ddf4ff;
  --bgColor-attention-emphasis: #9a6700;
  --bgColor-attention-muted: #fff8c5;
  --bgColor-danger-emphasis: #cf222e;
  --bgColor-danger-muted: #ffebe9;
  --bgColor-disabled: #eff2f5;
  --bgColor-done-emphasis: #8250df;
  --bgColor-done-muted: #fbefff;
  --bgColor-emphasis: #25292e;
  --bgColor-inverse: #25292e;
  --bgColor-muted: #f6f8fa;
  --bgColor-neutral-emphasis: #59636e;
  --bgColor-neutral-muted: #818b981f;
  --bgColor-open-emphasis: var(--bgColor-success-emphasis);
  --bgColor-severe-emphasis: #bc4c00;
  --bgColor-severe-muted: #fff1e5;
  --bgColor-sponsors-emphasis: #bf3989;
  --bgColor-sponsors-muted: #ffeff7;
  --bgColor-success-muted: #dafbe1;
  --bgColor-transparent: #ffffff00;
  --borderColor-accent-emphasis: #0969da;
  --borderColor-accent-muted: #54aeff66;
  --borderColor-attention-emphasis: #9a6700;
  --borderColor-attention-muted: #d4a72c66;
  --borderColor-danger-emphasis: #cf222e;
  --borderColor-danger-muted: #ff818266;
  --borderColor-default: #d1d9e0;
  --borderColor-disabled: #818b981a;
  --borderColor-done-emphasis: #8250df;
  --borderColor-done-muted: #c297ff66;
  --borderColor-emphasis: #818b98;
  --borderColor-neutral-emphasis: #59636e;
  --borderColor-severe-emphasis: #bc4c00;
  --borderColor-severe-muted: #fb8f4466;
  --borderColor-sponsors-emphasis: #bf3989;
  --borderColor-sponsors-muted: #ff80c866;
  --borderColor-success-emphasis: #1a7f37;
  --borderColor-success-muted: #4ac26b66;
  --borderColor-transparent: #ffffff00;
  --button-danger-bgColor-hover: #a40e26;
  --button-danger-fgColor-disabled: #d1242f80;
  --button-danger-fgColor-rest: var(--fgColor-danger);
  --button-danger-iconColor-rest: var(--fgColor-danger);
  --button-danger-shadow-selected: inset 0px 1px 0px 0px #4c001433;
  --button-inactive-bgColor: #e6eaef;
  --button-inactive-fgColor: #59636e;
  --button-invisible-bgColor-disabled: #ffffff00;
  --button-invisible-borderColor-disabled: #ffffff00;
  --button-outline-shadow-selected: inset 0px 1px 0px 0px #00215533;
  --button-primary-bgColor-rest: var(--bgColor-success-emphasis);
  --button-primary-borderColor-disabled: var(--button-primary-bgColor-disabled);
  --button-primary-shadow-selected: inset 0px 1px 0px 0px #002d114d;
  --button-star-iconColor: #eac54f;
  --buttonCounter-danger-fgColor-disabled: #d1242f80;
  --buttonCounter-outline-fgColor-rest: #0550ae;
  --buttonCounter-primary-bgColor-rest: #002d1133;
  --codeMirror-syntax-fgColor-constant: #0550ae;
  --codeMirror-syntax-fgColor-entity: #8250df;
  --codeMirror-syntax-fgColor-keyword: #cf222e;
  --codeMirror-syntax-fgColor-storage: #cf222e;
  --codeMirror-syntax-fgColor-string: #0a3069;
  --codeMirror-syntax-fgColor-support: #0550ae;
  --codeMirror-syntax-fgColor-variable: #953800;
  --color-ansi-black-bright: #393f46;
  --color-ansi-blue: #0969da;
  --color-ansi-blue-bright: #218bff;
  --color-ansi-gray: #59636e;
  --color-ansi-green: #116329;
  --color-ansi-green-bright: #1a7f37;
  --color-ansi-magenta: #8250df;
  --color-ansi-magenta-bright: #a475f9;
  --color-ansi-red: #cf222e;
  --color-ansi-red-bright: #a40e26;
  --color-ansi-white: #59636e;
  --color-ansi-white-bright: #818b98;
  --color-ansi-yellow: #4d2d00;
  --color-ansi-yellow-bright: #633c01;
  --color-prettylights-syntax-brackethighlighter-angle: #59636e;
  --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
  --color-prettylights-syntax-carriage-return-bg: #cf222e;
  --color-prettylights-syntax-carriage-return-text: #f6f8fa;
  --color-prettylights-syntax-comment: #59636e;
  --color-prettylights-syntax-constant: #0550ae;
  --color-prettylights-syntax-constant-other-reference-link: #0a3069;
  --color-prettylights-syntax-entity: #6639ba;
  --color-prettylights-syntax-entity-tag: #0550ae;
  --color-prettylights-syntax-invalid-illegal-bg: #82071e;
  --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
  --color-prettylights-syntax-keyword: #cf222e;
  --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
  --color-prettylights-syntax-markup-changed-text: #953800;
  --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
  --color-prettylights-syntax-markup-deleted-text: #82071e;
  --color-prettylights-syntax-markup-heading: #0550ae;
  --color-prettylights-syntax-markup-ignored-bg: #0550ae;
  --color-prettylights-syntax-markup-ignored-text: #d1d9e0;
  --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
  --color-prettylights-syntax-markup-inserted-text: #116329;
  --color-prettylights-syntax-markup-list: #3b2300;
  --color-prettylights-syntax-meta-diff-range: #8250df;
  --color-prettylights-syntax-string: #0a3069;
  --color-prettylights-syntax-string-regexp: #116329;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #818b98;
  --color-prettylights-syntax-variable: #953800;
  --contribution-default-bgColor-0: #eff2f5;
  --contribution-default-bgColor-1: #aceebb;
  --contribution-default-bgColor-2: #4ac26b;
  --contribution-default-bgColor-3: #2da44e;
  --contribution-default-bgColor-4: #116329;
  --contribution-default-borderColor-0: #1f23280d;
  --contribution-default-borderColor-1: #1f23280d;
  --contribution-default-borderColor-2: #1f23280d;
  --contribution-default-borderColor-3: #1f23280d;
  --contribution-default-borderColor-4: #1f23280d;
  --contribution-halloween-bgColor-1: #f0db3d;
  --contribution-halloween-bgColor-2: #ffd642;
  --contribution-halloween-bgColor-3: #f68c41;
  --contribution-halloween-bgColor-4: #1f2328;
  --contribution-winter-bgColor-1: #b6e3ff;
  --contribution-winter-bgColor-2: #54aeff;
  --contribution-winter-bgColor-3: #0969da;
  --contribution-winter-bgColor-4: #0a3069;
  --control-bgColor-active: #e6eaef;
  --control-bgColor-hover: #eff2f5;
  --control-bgColor-rest: #f6f8fa;
  --control-checked-borderColor-active: var(--control-checked-bgColor-active);
  --control-checked-borderColor-hover: var(--control-checked-bgColor-hover);
  --control-danger-fgColor-hover: var(--fgColor-danger);
  --control-danger-fgColor-rest: var(--fgColor-danger);
  --control-fgColor-rest: #25292e;
  --control-transparent-bgColor-active: #818b9826;
  --control-transparent-bgColor-hover: #818b981a;
  --control-transparent-bgColor-rest: #ffffff00;
  --control-transparent-bgColor-selected: #818b9826;
  --control-transparent-borderColor-active: #ffffff00;
  --control-transparent-borderColor-hover: #ffffff00;
  --control-transparent-borderColor-rest: #ffffff00;
  --controlTrack-bgColor-active: #dae0e7;
  --controlTrack-bgColor-hover: #e0e6eb;
  --controlTrack-bgColor-rest: #e6eaef;
  --controlTrack-fgColor-rest: #59636e;
  --counter-borderColor: #ffffff00;
  --data-auburn-color-emphasis: #9d615c;
  --data-auburn-color-muted: #f2e9e9;
  --data-blue-color-emphasis: #006edb;
  --data-blue-color-muted: #d1f0ff;
  --data-brown-color-emphasis: #856d4c;
  --data-brown-color-muted: #eeeae2;
  --data-coral-color-emphasis: #d43511;
  --data-coral-color-muted: #ffe5db;
  --data-gray-color-emphasis: #808fa3;
  --data-gray-color-muted: #e8ecf2;
  --data-green-color-emphasis: #30a147;
  --data-green-color-muted: #caf7ca;
  --data-lemon-color-emphasis: #866e04;
  --data-lemon-color-muted: #f7eea1;
  --data-lime-color-emphasis: #527a29;
  --data-lime-color-muted: #e3f2b5;
  --data-olive-color-emphasis: #64762d;
  --data-olive-color-muted: #f0f0ad;
  --data-orange-color-emphasis: #eb670f;
  --data-orange-color-muted: #ffe7d1;
  --data-pine-color-emphasis: #167e53;
  --data-pine-color-muted: #bff8db;
  --data-pink-color-emphasis: #ce2c85;
  --data-pink-color-muted: #ffe5f1;
  --data-plum-color-emphasis: #a830e8;
  --data-plum-color-muted: #f8e5ff;
  --data-purple-color-emphasis: #894ceb;
  --data-purple-color-muted: #f1e5ff;
  --data-red-color-emphasis: #df0c24;
  --data-red-color-muted: #ffe2e0;
  --data-teal-color-emphasis: #179b9b;
  --data-teal-color-muted: #c7f5ef;
  --data-yellow-color-emphasis: #b88700;
  --data-yellow-color-muted: #ffec9e;
  --diffBlob-additionNum-bgColor: #aceebb;
  --diffBlob-additionWord-bgColor: #aceebb;
  --diffBlob-deletionNum-bgColor: #ffcecb;
  --diffBlob-deletionWord-bgColor: #ffcecb;
  --diffBlob-hunkNum-bgColor-rest: #b6e3ff;
  --display-auburn-bgColor-emphasis: #9d615c;
  --display-auburn-bgColor-muted: #f2e9e9;
  --display-auburn-borderColor-emphasis: #9d615c;
  --display-auburn-borderColor-muted: #e6d6d5;
  --display-auburn-fgColor: #8a5551;
  --display-blue-bgColor-emphasis: #006edb;
  --display-blue-bgColor-muted: #d1f0ff;
  --display-blue-borderColor-emphasis: #006edb;
  --display-blue-borderColor-muted: #ade1ff;
  --display-blue-fgColor: #005fcc;
  --display-brown-bgColor-emphasis: #856d4c;
  --display-brown-bgColor-muted: #eeeae2;
  --display-brown-borderColor-emphasis: #856d4c;
  --display-brown-borderColor-muted: #dfd7c8;
  --display-brown-fgColor: #755f43;
  --display-coral-bgColor-emphasis: #d43511;
  --display-coral-bgColor-muted: #ffe5db;
  --display-coral-borderColor-emphasis: #d43511;
  --display-coral-borderColor-muted: #fecebe;
  --display-coral-fgColor: #ba2e12;
  --display-cyan-bgColor-emphasis: #007b94;
  --display-cyan-bgColor-muted: #bdf4ff;
  --display-cyan-borderColor-emphasis: #007b94;
  --display-cyan-borderColor-muted: #7ae9ff;
  --display-cyan-fgColor: #006a80;
  --display-gray-bgColor-emphasis: #647182;
  --display-gray-bgColor-muted: #e8ecf2;
  --display-gray-borderColor-emphasis: #647182;
  --display-gray-borderColor-muted: #d2dae4;
  --display-gray-fgColor: #5c6570;
  --display-green-bgColor-emphasis: #2c8141;
  --display-green-bgColor-muted: #caf7ca;
  --display-green-borderColor-emphasis: #2c8141;
  --display-green-borderColor-muted: #9ceda0;
  --display-green-fgColor: #2b6e3f;
  --display-indigo-bgColor-emphasis: #5a61e7;
  --display-indigo-bgColor-muted: #e5e9ff;
  --display-indigo-borderColor-emphasis: #5a61e7;
  --display-indigo-borderColor-muted: #d2d7fe;
  --display-indigo-fgColor: #494edf;
  --display-lemon-bgColor-emphasis: #866e04;
  --display-lemon-bgColor-muted: #f7eea1;
  --display-lemon-borderColor-emphasis: #866e04;
  --display-lemon-borderColor-muted: #f0db3d;
  --display-lemon-fgColor: #786002;
  --display-lime-bgColor-emphasis: #527a29;
  --display-lime-bgColor-muted: #e3f2b5;
  --display-lime-borderColor-emphasis: #527a29;
  --display-lime-borderColor-muted: #c7e580;
  --display-lime-fgColor: #476c28;
  --display-olive-bgColor-emphasis: #64762d;
  --display-olive-bgColor-muted: #f0f0ad;
  --display-olive-borderColor-emphasis: #64762d;
  --display-olive-borderColor-muted: #dbe170;
  --display-olive-fgColor: #56682c;
  --display-orange-bgColor-emphasis: #b8500f;
  --display-orange-bgColor-muted: #ffe7d1;
  --display-orange-borderColor-emphasis: #b8500f;
  --display-orange-borderColor-muted: #fecfaa;
  --display-orange-fgColor: #a24610;
  --display-pine-bgColor-emphasis: #167e53;
  --display-pine-bgColor-muted: #bff8db;
  --display-pine-borderColor-emphasis: #167e53;
  --display-pine-borderColor-muted: #80efb9;
  --display-pine-fgColor: #156f4b;
  --display-pink-bgColor-emphasis: #ce2c85;
  --display-pink-bgColor-muted: #ffe5f1;
  --display-pink-borderColor-emphasis: #ce2c85;
  --display-pink-borderColor-muted: #fdc9e2;
  --display-pink-fgColor: #b12f79;
  --display-plum-bgColor-emphasis: #a830e8;
  --display-plum-bgColor-muted: #f8e5ff;
  --display-plum-borderColor-emphasis: #a830e8;
  --display-plum-borderColor-muted: #f0cdfe;
  --display-plum-fgColor: #961edc;
  --display-purple-bgColor-emphasis: #894ceb;
  --display-purple-bgColor-muted: #f1e5ff;
  --display-purple-borderColor-emphasis: #894ceb;
  --display-purple-borderColor-muted: #e6d2fe;
  --display-purple-fgColor: #783ae4;
  --display-red-bgColor-emphasis: #df0c24;
  --display-red-bgColor-muted: #ffe2e0;
  --display-red-borderColor-emphasis: #df0c24;
  --display-red-borderColor-muted: #fecdcd;
  --display-red-fgColor: #c50d28;
  --display-teal-bgColor-emphasis: #127e81;
  --display-teal-bgColor-muted: #c7f5ef;
  --display-teal-borderColor-emphasis: #127e81;
  --display-teal-borderColor-muted: #89ebe1;
  --display-teal-fgColor: #106e75;
  --display-yellow-bgColor-emphasis: #946a00;
  --display-yellow-bgColor-muted: #ffec9e;
  --display-yellow-borderColor-emphasis: #946a00;
  --display-yellow-borderColor-muted: #ffd642;
  --display-yellow-fgColor: #805900;
  --fgColor-accent: #0969da;
  --fgColor-attention: #9a6700;
  --fgColor-closed: var(--fgColor-danger);
  --fgColor-disabled: #818b98;
  --fgColor-done: #8250df;
  --fgColor-muted: #59636e;
  --fgColor-neutral: #59636e;
  --fgColor-severe: #bc4c00;
  --fgColor-sponsors: #bf3989;
  --fgColor-success: #1a7f37;
  --header-bgColor: #25292e;
  --header-borderColor-divider: #818b98;
  --headerSearch-bgColor: #25292e;
  --headerSearch-borderColor: #818b98;
  --highlight-neutral-bgColor: #fff8c5;
  --label-auburn-bgColor-active: #d4b7b5;
  --label-auburn-bgColor-hover: #e6d6d5;
  --label-auburn-bgColor-rest: #f2e9e9;
  --label-auburn-fgColor-active: #5d3937;
  --label-auburn-fgColor-hover: #744744;
  --label-auburn-fgColor-rest: #8a5551;
  --label-blue-bgColor-active: #75c8ff;
  --label-blue-bgColor-hover: #ade1ff;
  --label-blue-bgColor-rest: #d1f0ff;
  --label-blue-fgColor-active: #003d99;
  --label-blue-fgColor-hover: #004db3;
  --label-blue-fgColor-rest: #005fcc;
  --label-brown-bgColor-active: #cbbda4;
  --label-brown-bgColor-hover: #dfd7c8;
  --label-brown-bgColor-rest: #eeeae2;
  --label-brown-fgColor-active: #51412f;
  --label-brown-fgColor-hover: #64513a;
  --label-brown-fgColor-rest: #755f43;
  --label-coral-bgColor-active: #fcab92;
  --label-coral-bgColor-hover: #fecebe;
  --label-coral-bgColor-rest: #ffe5db;
  --label-coral-fgColor-active: #7e2011;
  --label-coral-fgColor-hover: #9b2712;
  --label-coral-fgColor-rest: #ba2e12;
  --label-cyan-bgColor-active: #00d0fa;
  --label-cyan-bgColor-hover: #7ae9ff;
  --label-cyan-bgColor-rest: #bdf4ff;
  --label-cyan-fgColor-active: #004857;
  --label-cyan-fgColor-hover: #00596b;
  --label-cyan-fgColor-rest: #006a80;
  --label-gray-bgColor-active: #b4c0cf;
  --label-gray-bgColor-hover: #d2dae4;
  --label-gray-bgColor-rest: #e8ecf2;
  --label-gray-fgColor-active: #424448;
  --label-gray-fgColor-hover: #4e535a;
  --label-gray-fgColor-rest: #5c6570;
  --label-green-bgColor-active: #54d961;
  --label-green-bgColor-hover: #9ceda0;
  --label-green-bgColor-rest: #caf7ca;
  --label-green-fgColor-active: #254b34;
  --label-green-fgColor-hover: #285c3b;
  --label-green-fgColor-rest: #2b6e3f;
  --label-indigo-bgColor-active: #b1b9fb;
  --label-indigo-bgColor-hover: #d2d7fe;
  --label-indigo-bgColor-rest: #e5e9ff;
  --label-indigo-fgColor-active: #2d2db4;
  --label-indigo-fgColor-hover: #393cd5;
  --label-indigo-fgColor-rest: #494edf;
  --label-lemon-bgColor-active: #d8bd0e;
  --label-lemon-bgColor-hover: #f0db3d;
  --label-lemon-bgColor-rest: #f7eea1;
  --label-lemon-fgColor-active: #523f00;
  --label-lemon-fgColor-hover: #654f01;
  --label-lemon-fgColor-rest: #786002;
  --label-lime-bgColor-active: #9bd039;
  --label-lime-bgColor-hover: #c7e580;
  --label-lime-bgColor-rest: #e3f2b5;
  --label-lime-fgColor-active: #2f4a21;
  --label-lime-fgColor-hover: #3a5b25;
  --label-lime-fgColor-rest: #476c28;
  --label-olive-bgColor-active: #b9c832;
  --label-olive-bgColor-hover: #dbe170;
  --label-olive-bgColor-rest: #f0f0ad;
  --label-olive-fgColor-active: #3b4927;
  --label-olive-fgColor-hover: #495a2b;
  --label-olive-fgColor-rest: #56682c;
  --label-orange-bgColor-active: #fbaf74;
  --label-orange-bgColor-hover: #fecfaa;
  --label-orange-bgColor-rest: #ffe7d1;
  --label-orange-fgColor-active: #70300f;
  --label-orange-fgColor-hover: #8d3c11;
  --label-orange-fgColor-rest: #a24610;
  --label-pine-bgColor-active: #1dd781;
  --label-pine-bgColor-hover: #80efb9;
  --label-pine-bgColor-rest: #bff8db;
  --label-pine-fgColor-active: #114b36;
  --label-pine-fgColor-hover: #135d41;
  --label-pine-fgColor-rest: #156f4b;
  --label-pink-bgColor-active: #f8a5cf;
  --label-pink-bgColor-hover: #fdc9e2;
  --label-pink-bgColor-rest: #ffe5f1;
  --label-pink-fgColor-active: #6e2b53;
  --label-pink-fgColor-hover: #8e2e66;
  --label-pink-fgColor-rest: #b12f79;
  --label-plum-bgColor-active: #e2a7fb;
  --label-plum-bgColor-hover: #f0cdfe;
  --label-plum-bgColor-rest: #f8e5ff;
  --label-plum-fgColor-active: #651d96;
  --label-plum-fgColor-hover: #7d1eb8;
  --label-plum-fgColor-rest: #961edc;
  --label-purple-bgColor-active: #d1b1fc;
  --label-purple-bgColor-hover: #e6d2fe;
  --label-purple-bgColor-rest: #f1e5ff;
  --label-purple-fgColor-active: #4f21ab;
  --label-purple-fgColor-hover: #6223d7;
  --label-purple-fgColor-rest: #783ae4;
  --label-red-bgColor-active: #fda5a7;
  --label-red-bgColor-hover: #fecdcd;
  --label-red-bgColor-rest: #ffe2e0;
  --label-red-fgColor-active: #880c27;
  --label-red-fgColor-hover: #a60c29;
  --label-red-fgColor-rest: #c50d28;
  --label-teal-bgColor-active: #22d3c7;
  --label-teal-bgColor-hover: #89ebe1;
  --label-teal-bgColor-rest: #c7f5ef;
  --label-teal-fgColor-active: #0a4852;
  --label-teal-fgColor-hover: #0d5b63;
  --label-teal-fgColor-rest: #106e75;
  --label-yellow-bgColor-active: #ebb400;
  --label-yellow-bgColor-hover: #ffd642;
  --label-yellow-bgColor-rest: #ffec9e;
  --label-yellow-fgColor-active: #5c3d00;
  --label-yellow-fgColor-hover: #704d00;
  --label-yellow-fgColor-rest: #805900;
  --menu-bgColor-active: #ffffff00;
  --overlay-backdrop-bgColor: #c8d1da66;
  --reactionButton-selected-bgColor-rest: #ddf4ff;
  --reactionButton-selected-fgColor-hover: #0550ae;
  --selectMenu-bgColor-active: #b6e3ff;
  --selectMenu-borderColor: #ffffff00;
  --shadow-floating-legacy: 0px 6px 12px -3px #25292e0a, 0px 6px 18px 0px #25292e1f;
  --shadow-resting-medium: 0px 1px 1px 0px #25292e1a, 0px 3px 6px 0px #25292e1f;
  --skeletonLoader-bgColor: #818b981a;
  --topicTag-borderColor: #ffffff00;
  --treeViewItem-leadingVisual-iconColor-rest: #54aeff;
  --underlineNav-borderColor-active: #fd8c73;
  --avatar-bgColor: #ffffff;
  --avatar-shadow: 0px 0px 0px 2px #ffffffcc;
  --bgColor-black: #1f2328;
  --bgColor-closed-emphasis: var(--bgColor-danger-emphasis);
  --bgColor-closed-muted: var(--bgColor-danger-muted);
  --bgColor-default: #ffffff;
  --bgColor-inset: var(--bgColor-muted);
  --bgColor-open-muted: var(--bgColor-success-muted);
  --bgColor-upsell-emphasis: var(--bgColor-done-emphasis);
  --bgColor-upsell-muted: var(--bgColor-done-muted);
  --bgColor-white: #ffffff;
  --border-accent-emphasis: 0.0625rem solid #0969da;
  --border-accent-muted: 0.0625rem solid #54aeff66;
  --border-attention-emphasis: 0.0625rem solid #9a6700;
  --border-attention-muted: 0.0625rem solid #d4a72c66;
  --border-danger-emphasis: 0.0625rem solid #cf222e;
  --border-danger-muted: 0.0625rem solid #ff818266;
  --border-default: 0.0625rem solid #d1d9e0;
  --border-disabled: 0.0625rem solid #818b981a;
  --border-done-emphasis: 0.0625rem solid #8250df;
  --border-done-muted: 0.0625rem solid #c297ff66;
  --border-emphasis: 0.0625rem solid #818b98;
  --border-neutral-emphasis: 0.0625rem solid #59636e;
  --border-severe-emphasis: 0.0625rem solid #bc4c00;
  --border-severe-muted: 0.0625rem solid #fb8f4466;
  --border-sponsors-emphasis: 0.0625rem solid #bf3989;
  --border-sponsors-muted: 0.0625rem solid #ff80c866;
  --border-success-emphasis: 0.0625rem solid #1a7f37;
  --border-success-muted: 0.0625rem solid #4ac26b66;
  --border-transparent: 0.0625rem solid #ffffff00;
  --borderColor-closed-emphasis: var(--borderColor-danger-emphasis);
  --borderColor-closed-muted: var(--borderColor-danger-muted);
  --borderColor-muted: #d1d9e0b3;
  --borderColor-open-emphasis: var(--borderColor-success-emphasis);
  --borderColor-open-muted: var(--borderColor-success-muted);
  --borderColor-translucent: #1f232826;
  --borderColor-upsell-emphasis: var(--borderColor-done-emphasis);
  --borderColor-upsell-muted: var(--borderColor-done-muted);
  --button-danger-bgColor-rest: var(--control-bgColor-rest);
  --button-danger-fgColor-active: #ffffff;
  --button-danger-fgColor-hover: #ffffff;
  --button-danger-iconColor-hover: #ffffff;
  --button-default-bgColor-active: var(--control-bgColor-active);
  --button-default-bgColor-hover: var(--control-bgColor-hover);
  --button-default-bgColor-rest: var(--control-bgColor-rest);
  --button-default-bgColor-selected: var(--control-bgColor-active);
  --button-default-fgColor-rest: var(--control-fgColor-rest);
  --button-default-shadow-resting: 0px 1px 0px 0px #1f23280a;
  --button-invisible-bgColor-active: var(--control-transparent-bgColor-active);
  --button-invisible-bgColor-hover: var(--control-transparent-bgColor-hover);
  --button-invisible-bgColor-rest: var(--control-transparent-bgColor-rest);
  --button-invisible-borderColor-hover: var(--control-transparent-borderColor-hover);
  --button-invisible-borderColor-rest: var(--control-transparent-borderColor-rest);
  --button-invisible-fgColor-active: var(--control-fgColor-rest);
  --button-invisible-fgColor-hover: var(--control-fgColor-rest);
  --button-invisible-fgColor-rest: var(--control-fgColor-rest);
  --button-invisible-iconColor-hover: var(--fgColor-muted);
  --button-invisible-iconColor-rest: var(--fgColor-muted);
  --button-outline-bgColor-hover: var(--bgColor-accent-emphasis);
  --button-outline-bgColor-rest: var(--control-bgColor-rest);
  --button-outline-fgColor-active: #ffffff;
  --button-outline-fgColor-disabled: #0969da80;
  --button-outline-fgColor-hover: #ffffff;
  --button-outline-fgColor-rest: var(--fgColor-accent);
  --button-primary-borderColor-rest: #1f232826;
  --button-primary-fgColor-disabled: #ffffffcc;
  --buttonCounter-danger-bgColor-disabled: #cf222e0d;
  --buttonCounter-danger-bgColor-hover: #ffffff33;
  --buttonCounter-danger-bgColor-rest: #cf222e1a;
  --buttonCounter-danger-fgColor-hover: #ffffff;
  --buttonCounter-default-bgColor-rest: var(--bgColor-neutral-muted);
  --buttonCounter-invisible-bgColor-rest: var(--bgColor-neutral-muted);
  --buttonCounter-outline-bgColor-disabled: #0969da0d;
  --buttonCounter-outline-bgColor-hover: #ffffff33;
  --buttonCounter-outline-bgColor-rest: #0969da1a;
  --buttonCounter-outline-fgColor-disabled: #0969da80;
  --buttonCounter-outline-fgColor-hover: #ffffff;
  --codeMirror-activeline-bgColor: var(--bgColor-neutral-muted);
  --codeMirror-gutterMarker-fgColor-muted: var(--fgColor-muted);
  --codeMirror-lineNumber-fgColor: var(--fgColor-muted);
  --codeMirror-selection-bgColor: var(--borderColor-accent-muted);
  --codeMirror-syntax-fgColor-comment: #1f2328;
  --color-ansi-black: #1f2328;
  --color-prettylights-syntax-markup-bold: #1f2328;
  --color-prettylights-syntax-markup-italic: #1f2328;
  --color-prettylights-syntax-storage-modifier-import: #1f2328;
  --control-bgColor-disabled: var(--bgColor-disabled);
  --control-bgColor-selected: var(--control-bgColor-rest);
  --control-borderColor-danger: var(--borderColor-danger-emphasis);
  --control-borderColor-disabled: var(--borderColor-disabled);
  --control-borderColor-emphasis: var(--borderColor-emphasis);
  --control-borderColor-rest: var(--borderColor-default);
  --control-borderColor-success: var(--borderColor-success-emphasis);
  --control-borderColor-warning: var(--borderColor-attention-emphasis);
  --control-checked-bgColor-disabled: var(--fgColor-disabled);
  --control-checked-bgColor-rest: var(--bgColor-accent-emphasis);
  --control-danger-bgColor-active: #ffebe966;
  --control-danger-bgColor-hover: var(--bgColor-danger-muted);
  --control-fgColor-disabled: var(--fgColor-disabled);
  --control-fgColor-placeholder: var(--fgColor-muted);
  --control-iconColor-rest: var(--fgColor-muted);
  --control-transparent-bgColor-disabled: var(--bgColor-disabled);
  --controlKnob-bgColor-checked: #ffffff;
  --controlKnob-bgColor-rest: #ffffff;
  --controlTrack-bgColor-disabled: var(--fgColor-disabled);
  --controlTrack-borderColor-disabled: var(--fgColor-disabled);
  --controlTrack-borderColor-rest: var(--borderColor-default);
  --counter-bgColor-emphasis: var(--bgColor-neutral-emphasis);
  --counter-bgColor-muted: var(--bgColor-neutral-muted);
  --data-auburn-color-gradientStop-1: var(--data-auburn-color-muted);
  --data-auburn-color-gradientStop-2: #f2e9e900;
  --data-blue-color-gradientStop-1: var(--data-blue-color-muted);
  --data-blue-color-gradientStop-2: #d1f0ff00;
  --data-brown-color-gradientStop-1: var(--data-brown-color-muted);
  --data-brown-color-gradientStop-2: #eeeae200;
  --data-coral-color-gradientStop-1: var(--data-coral-color-muted);
  --data-coral-color-gradientStop-2: #ffe5db00;
  --data-gray-color-gradientStop-1: var(--data-gray-color-muted);
  --data-gray-color-gradientStop-2: #e8ecf200;
  --data-green-color-gradientStop-1: var(--data-green-color-muted);
  --data-green-color-gradientStop-2: #caf7ca00;
  --data-lemon-color-gradientStop-1: var(--data-lemon-color-muted);
  --data-lemon-color-gradientStop-2: #f7eea100;
  --data-lime-color-gradientStop-1: var(--data-lime-color-muted);
  --data-lime-color-gradientStop-2: #e3f2b500;
  --data-olive-color-gradientStop-1: var(--data-olive-color-muted);
  --data-olive-color-gradientStop-2: #f0f0ad00;
  --data-orange-color-gradientStop-1: var(--data-orange-color-muted);
  --data-orange-color-gradientStop-2: #ffe7d100;
  --data-pine-color-gradientStop-1: var(--data-pine-color-muted);
  --data-pine-color-gradientStop-2: #bff8db00;
  --data-pink-color-gradientStop-1: var(--data-pink-color-muted);
  --data-pink-color-gradientStop-2: #ffe5f100;
  --data-plum-color-gradientStop-1: var(--data-plum-color-muted);
  --data-plum-color-gradientStop-2: #f8e5ff00;
  --data-purple-color-gradientStop-1: var(--data-purple-color-muted);
  --data-purple-color-gradientStop-2: #f1e5ff00;
  --data-red-color-gradientStop-1: var(--data-red-color-muted);
  --data-red-color-gradientStop-2: #ffe2e000;
  --data-teal-color-gradientStop-1: var(--data-teal-color-muted);
  --data-teal-color-gradientStop-2: #c7f5ef00;
  --data-yellow-color-gradientStop-1: var(--data-yellow-color-muted);
  --data-yellow-color-gradientStop-2: #ffec9e00;
  --diffBlob-additionLine-bgColor: var(--bgColor-success-muted);
  --diffBlob-deletionLine-bgColor: var(--bgColor-danger-muted);
  --diffBlob-emptyLine-bgColor: var(--bgColor-muted);
  --diffBlob-emptyNum-bgColor: var(--bgColor-muted);
  --diffBlob-expander-iconColor: var(--fgColor-muted);
  --diffBlob-hunkLine-bgColor: var(--bgColor-accent-muted);
  --diffBlob-hunkLine-fgColor: var(--fgColor-muted);
  --diffBlob-hunkNum-bgColor-hover: var(--bgColor-accent-emphasis);
  --fgColor-black: #1f2328;
  --fgColor-default: #1f2328;
  --fgColor-link: var(--fgColor-accent);
  --fgColor-onEmphasis: #ffffff;
  --fgColor-onInverse: #ffffff;
  --fgColor-open: var(--fgColor-success);
  --fgColor-upsell: var(--fgColor-done);
  --fgColor-white: #ffffff;
  --focus-outlineColor: var(--borderColor-accent-emphasis);
  --header-fgColor-default: #ffffffb3;
  --header-fgColor-logo: #ffffff;
  --overlay-bgColor: #ffffff;
  --page-header-bgColor: var(--bgColor-muted);
  --selection-bgColor: #0969da33;
  --shadow-inset: inset 0px 1px 0px 0px #1f23280a;
  --shadow-resting-small: 0px 1px 1px 0px #1f23280f, 0px 1px 3px 0px #1f23280f;
  --shadow-resting-xsmall: 0px 1px 1px 0px #1f23280f;
  --sideNav-bgColor-selected: #ffffff;
  --timelineBadge-bgColor: var(--bgColor-muted);
  --tooltip-bgColor: var(--bgColor-emphasis);
  --underlineNav-iconColor-rest: var(--fgColor-muted);
  --avatar-borderColor: var(--borderColor-translucent);
  --border-closed-emphasis: var(--border-danger-emphasis);
  --border-closed-muted: var(--border-danger-muted);
  --border-muted: 0.0625rem solid #d1d9e0b3;
  --border-open-emphasis: var(--border-success-emphasis);
  --border-open-muted: var(--border-success-muted);
  --border-upsell-emphasis: 0.0625rem solid #8250df;
  --border-upsell-muted: 0.0625rem solid #c297ff66;
  --borderColor-neutral-muted: var(--borderColor-muted);
  --button-danger-bgColor-disabled: var(--control-bgColor-disabled);
  --button-danger-borderColor-hover: var(--button-primary-borderColor-rest);
  --button-danger-borderColor-rest: var(--control-borderColor-rest);
  --button-default-bgColor-disabled: var(--control-bgColor-disabled);
  --button-default-borderColor-active: var(--control-borderColor-rest);
  --button-default-borderColor-disabled: var(--control-borderColor-disabled);
  --button-default-borderColor-rest: var(--control-borderColor-rest);
  --button-invisible-fgColor-disabled: var(--control-fgColor-disabled);
  --button-invisible-iconColor-disabled: var(--control-fgColor-disabled);
  --button-outline-bgColor-disabled: var(--control-bgColor-disabled);
  --button-primary-borderColor-active: var(--button-primary-borderColor-rest);
  --button-primary-borderColor-hover: var(--button-primary-borderColor-rest);
  --button-primary-fgColor-rest: var(--fgColor-white);
  --button-primary-iconColor-rest: #ffffffcc;
  --card-bgColor: var(--bgColor-default);
  --codeMirror-bgColor: var(--bgColor-default);
  --codeMirror-cursor-fgColor: var(--fgColor-default);
  --codeMirror-fgColor: var(--fgColor-default);
  --codeMirror-gutterMarker-fgColor-default: var(--bgColor-default);
  --codeMirror-gutters-bgColor: var(--bgColor-default);
  --codeMirror-lines-bgColor: var(--bgColor-default);
  --codeMirror-matchingBracket-fgColor: var(--fgColor-default);
  --control-borderColor-selected: var(--control-bgColor-selected);
  --control-checked-borderColor-disabled: var(--control-checked-bgColor-disabled);
  --control-checked-borderColor-rest: var(--control-checked-bgColor-rest);
  --control-checked-fgColor-disabled: var(--fgColor-onEmphasis);
  --control-checked-fgColor-rest: var(--fgColor-onEmphasis);
  --controlKnob-bgColor-disabled: var(--control-bgColor-disabled);
  --controlKnob-borderColor-checked: var(--control-checked-bgColor-rest);
  --controlKnob-borderColor-disabled: var(--control-bgColor-disabled);
  --controlKnob-borderColor-rest: var(--control-borderColor-emphasis);
  --controlTrack-fgColor-disabled: var(--fgColor-onEmphasis);
  --data-auburn-gradient: linear-gradient(180deg, #f2e9e9 0%, #f2e9e900 100%);
  --data-blue-gradient: linear-gradient(180deg, #d1f0ff 0%, #d1f0ff00 100%);
  --data-brown-gradient: linear-gradient(180deg, #eeeae2 0%, #eeeae200 100%);
  --data-coral-gradient: linear-gradient(180deg, #ffe5db 0%, #ffe5db00 100%);
  --data-gray-gradient: linear-gradient(180deg, #e8ecf2 0%, #e8ecf200 100%);
  --data-green-gradient: linear-gradient(180deg, #caf7ca 0%, #caf7ca00 100%);
  --data-lemon-gradient: linear-gradient(180deg, #f7eea1 0%, #f7eea100 100%);
  --data-lime-gradient: linear-gradient(180deg, #e3f2b5 0%, #e3f2b500 100%);
  --data-olive-gradient: linear-gradient(180deg, #f0f0ad 0%, #f0f0ad00 100%);
  --data-orange-gradient: linear-gradient(180deg, #ffe7d1 0%, #ffe7d100 100%);
  --data-pine-gradient: linear-gradient(180deg, #bff8db 0%, #bff8db00 100%);
  --data-pink-gradient: linear-gradient(180deg, #ffe5f1 0%, #ffe5f100 100%);
  --data-plum-gradient: linear-gradient(180deg, #f8e5ff 0%, #f8e5ff00 100%);
  --data-purple-gradient: linear-gradient(180deg, #f1e5ff 0%, #f1e5ff00 100%);
  --data-red-gradient: linear-gradient(180deg, #ffe2e0 0%, #ffe2e000 100%);
  --data-teal-gradient: linear-gradient(180deg, #c7f5ef 0%, #c7f5ef00 100%);
  --data-yellow-gradient: linear-gradient(180deg, #ffec9e 0%, #ffec9e00 100%);
  --diffBlob-additionLine-fgColor: var(--fgColor-default);
  --diffBlob-additionNum-fgColor: var(--fgColor-default);
  --diffBlob-additionWord-fgColor: var(--fgColor-default);
  --diffBlob-deletionLine-fgColor: var(--fgColor-default);
  --diffBlob-deletionNum-fgColor: var(--fgColor-default);
  --diffBlob-deletionWord-fgColor: var(--fgColor-default);
  --diffBlob-hunkNum-fgColor-hover: var(--fgColor-onEmphasis);
  --diffBlob-hunkNum-fgColor-rest: var(--fgColor-default);
  --focus-outline: 2px solid #0969da;
  --overlay-borderColor: #d1d9e080;
  --reactionButton-selected-fgColor-rest: var(--fgColor-link);
  --tooltip-fgColor: var(--fgColor-onEmphasis);
  --underlineNav-borderColor-hover: var(--borderColor-muted);
  --border-neutral-muted: 0.0625rem solid #d1d9e0b3;
  --button-danger-borderColor-active: var(--button-danger-borderColor-hover);
  --button-default-borderColor-hover: var(--button-default-borderColor-rest);
  --button-outline-borderColor-hover: var(--button-primary-borderColor-hover);
  --shadow-floating-large: 0px 0px 0px 1px #d1d9e0, 0px 40px 80px 0px #25292e3d;
  --shadow-floating-medium: 0px 0px 0px 1px #d1d9e0, 0px 8px 16px -4px #25292e14, 0px 4px 32px -4px #25292e14, 0px 24px 48px -12px #25292e14, 0px 48px 96px -24px #25292e14;
  --shadow-floating-small: 0px 0px 0px 1px #d1d9e080, 0px 6px 12px -3px #25292e0a, 0px 6px 18px 0px #25292e1f;
  --shadow-floating-xlarge: 0px 0px 0px 1px #d1d9e0, 0px 56px 112px 0px #25292e52;
  --button-outline-borderColor-active: var(--button-outline-borderColor-hover);
}
@media (prefers-color-scheme: dark) {
  [data-color-mode="auto"][data-dark-theme="light"],
  [data-color-mode="auto"][data-dark-theme="light"] ::backdrop {
    --bgColor-success-emphasis: #1f883d;
    --button-danger-bgColor-active: #8b0820;
    --button-outline-bgColor-active: #0757ba;
    --button-primary-bgColor-active: #197935;
    --button-primary-bgColor-disabled: #95d8a6;
    --button-primary-bgColor-hover: #1c8139;
    --buttonCounter-danger-fgColor-rest: #c21c2c;
    --color-ansi-cyan: #1b7c83;
    --color-ansi-cyan-bright: #3192aa;
    --control-checked-bgColor-active: #0757ba;
    --control-checked-bgColor-hover: #0860ca;
    --fgColor-danger: #d1242f;
    --reactionButton-selected-bgColor-hover: #caecff;
    --avatarStack-fade-bgColor-default: #c8d1da;
    --avatarStack-fade-bgColor-muted: #dae0e7;
    --bgColor-accent-emphasis: #0969da;
    --bgColor-accent-muted: #ddf4ff;
    --bgColor-attention-emphasis: #9a6700;
    --bgColor-attention-muted: #fff8c5;
    --bgColor-danger-emphasis: #cf222e;
    --bgColor-danger-muted: #ffebe9;
    --bgColor-disabled: #eff2f5;
    --bgColor-done-emphasis: #8250df;
    --bgColor-done-muted: #fbefff;
    --bgColor-emphasis: #25292e;
    --bgColor-inverse: #25292e;
    --bgColor-muted: #f6f8fa;
    --bgColor-neutral-emphasis: #59636e;
    --bgColor-neutral-muted: #818b981f;
    --bgColor-open-emphasis: var(--bgColor-success-emphasis);
    --bgColor-severe-emphasis: #bc4c00;
    --bgColor-severe-muted: #fff1e5;
    --bgColor-sponsors-emphasis: #bf3989;
    --bgColor-sponsors-muted: #ffeff7;
    --bgColor-success-muted: #dafbe1;
    --bgColor-transparent: #ffffff00;
    --borderColor-accent-emphasis: #0969da;
    --borderColor-accent-muted: #54aeff66;
    --borderColor-attention-emphasis: #9a6700;
    --borderColor-attention-muted: #d4a72c66;
    --borderColor-danger-emphasis: #cf222e;
    --borderColor-danger-muted: #ff818266;
    --borderColor-default: #d1d9e0;
    --borderColor-disabled: #818b981a;
    --borderColor-done-emphasis: #8250df;
    --borderColor-done-muted: #c297ff66;
    --borderColor-emphasis: #818b98;
    --borderColor-neutral-emphasis: #59636e;
    --borderColor-severe-emphasis: #bc4c00;
    --borderColor-severe-muted: #fb8f4466;
    --borderColor-sponsors-emphasis: #bf3989;
    --borderColor-sponsors-muted: #ff80c866;
    --borderColor-success-emphasis: #1a7f37;
    --borderColor-success-muted: #4ac26b66;
    --borderColor-transparent: #ffffff00;
    --button-danger-bgColor-hover: #a40e26;
    --button-danger-fgColor-disabled: #d1242f80;
    --button-danger-fgColor-rest: var(--fgColor-danger);
    --button-danger-iconColor-rest: var(--fgColor-danger);
    --button-danger-shadow-selected: inset 0px 1px 0px 0px #4c001433;
    --button-inactive-bgColor: #e6eaef;
    --button-inactive-fgColor: #59636e;
    --button-invisible-bgColor-disabled: #ffffff00;
    --button-invisible-borderColor-disabled: #ffffff00;
    --button-outline-shadow-selected: inset 0px 1px 0px 0px #00215533;
    --button-primary-bgColor-rest: var(--bgColor-success-emphasis);
    --button-primary-borderColor-disabled: var(--button-primary-bgColor-disabled);
    --button-primary-shadow-selected: inset 0px 1px 0px 0px #002d114d;
    --button-star-iconColor: #eac54f;
    --buttonCounter-danger-fgColor-disabled: #d1242f80;
    --buttonCounter-outline-fgColor-rest: #0550ae;
    --buttonCounter-primary-bgColor-rest: #002d1133;
    --codeMirror-syntax-fgColor-constant: #0550ae;
    --codeMirror-syntax-fgColor-entity: #8250df;
    --codeMirror-syntax-fgColor-keyword: #cf222e;
    --codeMirror-syntax-fgColor-storage: #cf222e;
    --codeMirror-syntax-fgColor-string: #0a3069;
    --codeMirror-syntax-fgColor-support: #0550ae;
    --codeMirror-syntax-fgColor-variable: #953800;
    --color-ansi-black-bright: #393f46;
    --color-ansi-blue: #0969da;
    --color-ansi-blue-bright: #218bff;
    --color-ansi-gray: #59636e;
    --color-ansi-green: #116329;
    --color-ansi-green-bright: #1a7f37;
    --color-ansi-magenta: #8250df;
    --color-ansi-magenta-bright: #a475f9;
    --color-ansi-red: #cf222e;
    --color-ansi-red-bright: #a40e26;
    --color-ansi-white: #59636e;
    --color-ansi-white-bright: #818b98;
    --color-ansi-yellow: #4d2d00;
    --color-ansi-yellow-bright: #633c01;
    --color-prettylights-syntax-brackethighlighter-angle: #59636e;
    --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
    --color-prettylights-syntax-carriage-return-bg: #cf222e;
    --color-prettylights-syntax-carriage-return-text: #f6f8fa;
    --color-prettylights-syntax-comment: #59636e;
    --color-prettylights-syntax-constant: #0550ae;
    --color-prettylights-syntax-constant-other-reference-link: #0a3069;
    --color-prettylights-syntax-entity: #6639ba;
    --color-prettylights-syntax-entity-tag: #0550ae;
    --color-prettylights-syntax-invalid-illegal-bg: #82071e;
    --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
    --color-prettylights-syntax-keyword: #cf222e;
    --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
    --color-prettylights-syntax-markup-changed-text: #953800;
    --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
    --color-prettylights-syntax-markup-deleted-text: #82071e;
    --color-prettylights-syntax-markup-heading: #0550ae;
    --color-prettylights-syntax-markup-ignored-bg: #0550ae;
    --color-prettylights-syntax-markup-ignored-text: #d1d9e0;
    --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
    --color-prettylights-syntax-markup-inserted-text: #116329;
    --color-prettylights-syntax-markup-list: #3b2300;
    --color-prettylights-syntax-meta-diff-range: #8250df;
    --color-prettylights-syntax-string: #0a3069;
    --color-prettylights-syntax-string-regexp: #116329;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #818b98;
    --color-prettylights-syntax-variable: #953800;
    --contribution-default-bgColor-0: #eff2f5;
    --contribution-default-bgColor-1: #aceebb;
    --contribution-default-bgColor-2: #4ac26b;
    --contribution-default-bgColor-3: #2da44e;
    --contribution-default-bgColor-4: #116329;
    --contribution-default-borderColor-0: #1f23280d;
    --contribution-default-borderColor-1: #1f23280d;
    --contribution-default-borderColor-2: #1f23280d;
    --contribution-default-borderColor-3: #1f23280d;
    --contribution-default-borderColor-4: #1f23280d;
    --contribution-halloween-bgColor-1: #f0db3d;
    --contribution-halloween-bgColor-2: #ffd642;
    --contribution-halloween-bgColor-3: #f68c41;
    --contribution-halloween-bgColor-4: #1f2328;
    --contribution-winter-bgColor-1: #b6e3ff;
    --contribution-winter-bgColor-2: #54aeff;
    --contribution-winter-bgColor-3: #0969da;
    --contribution-winter-bgColor-4: #0a3069;
    --control-bgColor-active: #e6eaef;
    --control-bgColor-hover: #eff2f5;
    --control-bgColor-rest: #f6f8fa;
    --control-checked-borderColor-active: var(--control-checked-bgColor-active);
    --control-checked-borderColor-hover: var(--control-checked-bgColor-hover);
    --control-danger-fgColor-hover: var(--fgColor-danger);
    --control-danger-fgColor-rest: var(--fgColor-danger);
    --control-fgColor-rest: #25292e;
    --control-transparent-bgColor-active: #818b9826;
    --control-transparent-bgColor-hover: #818b981a;
    --control-transparent-bgColor-rest: #ffffff00;
    --control-transparent-bgColor-selected: #818b9826;
    --control-transparent-borderColor-active: #ffffff00;
    --control-transparent-borderColor-hover: #ffffff00;
    --control-transparent-borderColor-rest: #ffffff00;
    --controlTrack-bgColor-active: #dae0e7;
    --controlTrack-bgColor-hover: #e0e6eb;
    --controlTrack-bgColor-rest: #e6eaef;
    --controlTrack-fgColor-rest: #59636e;
    --counter-borderColor: #ffffff00;
    --data-auburn-color-emphasis: #9d615c;
    --data-auburn-color-muted: #f2e9e9;
    --data-blue-color-emphasis: #006edb;
    --data-blue-color-muted: #d1f0ff;
    --data-brown-color-emphasis: #856d4c;
    --data-brown-color-muted: #eeeae2;
    --data-coral-color-emphasis: #d43511;
    --data-coral-color-muted: #ffe5db;
    --data-gray-color-emphasis: #808fa3;
    --data-gray-color-muted: #e8ecf2;
    --data-green-color-emphasis: #30a147;
    --data-green-color-muted: #caf7ca;
    --data-lemon-color-emphasis: #866e04;
    --data-lemon-color-muted: #f7eea1;
    --data-lime-color-emphasis: #527a29;
    --data-lime-color-muted: #e3f2b5;
    --data-olive-color-emphasis: #64762d;
    --data-olive-color-muted: #f0f0ad;
    --data-orange-color-emphasis: #eb670f;
    --data-orange-color-muted: #ffe7d1;
    --data-pine-color-emphasis: #167e53;
    --data-pine-color-muted: #bff8db;
    --data-pink-color-emphasis: #ce2c85;
    --data-pink-color-muted: #ffe5f1;
    --data-plum-color-emphasis: #a830e8;
    --data-plum-color-muted: #f8e5ff;
    --data-purple-color-emphasis: #894ceb;
    --data-purple-color-muted: #f1e5ff;
    --data-red-color-emphasis: #df0c24;
    --data-red-color-muted: #ffe2e0;
    --data-teal-color-emphasis: #179b9b;
    --data-teal-color-muted: #c7f5ef;
    --data-yellow-color-emphasis: #b88700;
    --data-yellow-color-muted: #ffec9e;
    --diffBlob-additionNum-bgColor: #aceebb;
    --diffBlob-additionWord-bgColor: #aceebb;
    --diffBlob-deletionNum-bgColor: #ffcecb;
    --diffBlob-deletionWord-bgColor: #ffcecb;
    --diffBlob-hunkNum-bgColor-rest: #b6e3ff;
    --display-auburn-bgColor-emphasis: #9d615c;
    --display-auburn-bgColor-muted: #f2e9e9;
    --display-auburn-borderColor-emphasis: #9d615c;
    --display-auburn-borderColor-muted: #e6d6d5;
    --display-auburn-fgColor: #8a5551;
    --display-blue-bgColor-emphasis: #006edb;
    --display-blue-bgColor-muted: #d1f0ff;
    --display-blue-borderColor-emphasis: #006edb;
    --display-blue-borderColor-muted: #ade1ff;
    --display-blue-fgColor: #005fcc;
    --display-brown-bgColor-emphasis: #856d4c;
    --display-brown-bgColor-muted: #eeeae2;
    --display-brown-borderColor-emphasis: #856d4c;
    --display-brown-borderColor-muted: #dfd7c8;
    --display-brown-fgColor: #755f43;
    --display-coral-bgColor-emphasis: #d43511;
    --display-coral-bgColor-muted: #ffe5db;
    --display-coral-borderColor-emphasis: #d43511;
    --display-coral-borderColor-muted: #fecebe;
    --display-coral-fgColor: #ba2e12;
    --display-cyan-bgColor-emphasis: #007b94;
    --display-cyan-bgColor-muted: #bdf4ff;
    --display-cyan-borderColor-emphasis: #007b94;
    --display-cyan-borderColor-muted: #7ae9ff;
    --display-cyan-fgColor: #006a80;
    --display-gray-bgColor-emphasis: #647182;
    --display-gray-bgColor-muted: #e8ecf2;
    --display-gray-borderColor-emphasis: #647182;
    --display-gray-borderColor-muted: #d2dae4;
    --display-gray-fgColor: #5c6570;
    --display-green-bgColor-emphasis: #2c8141;
    --display-green-bgColor-muted: #caf7ca;
    --display-green-borderColor-emphasis: #2c8141;
    --display-green-borderColor-muted: #9ceda0;
    --display-green-fgColor: #2b6e3f;
    --display-indigo-bgColor-emphasis: #5a61e7;
    --display-indigo-bgColor-muted: #e5e9ff;
    --display-indigo-borderColor-emphasis: #5a61e7;
    --display-indigo-borderColor-muted: #d2d7fe;
    --display-indigo-fgColor: #494edf;
    --display-lemon-bgColor-emphasis: #866e04;
    --display-lemon-bgColor-muted: #f7eea1;
    --display-lemon-borderColor-emphasis: #866e04;
    --display-lemon-borderColor-muted: #f0db3d;
    --display-lemon-fgColor: #786002;
    --display-lime-bgColor-emphasis: #527a29;
    --display-lime-bgColor-muted: #e3f2b5;
    --display-lime-borderColor-emphasis: #527a29;
    --display-lime-borderColor-muted: #c7e580;
    --display-lime-fgColor: #476c28;
    --display-olive-bgColor-emphasis: #64762d;
    --display-olive-bgColor-muted: #f0f0ad;
    --display-olive-borderColor-emphasis: #64762d;
    --display-olive-borderColor-muted: #dbe170;
    --display-olive-fgColor: #56682c;
    --display-orange-bgColor-emphasis: #b8500f;
    --display-orange-bgColor-muted: #ffe7d1;
    --display-orange-borderColor-emphasis: #b8500f;
    --display-orange-borderColor-muted: #fecfaa;
    --display-orange-fgColor: #a24610;
    --display-pine-bgColor-emphasis: #167e53;
    --display-pine-bgColor-muted: #bff8db;
    --display-pine-borderColor-emphasis: #167e53;
    --display-pine-borderColor-muted: #80efb9;
    --display-pine-fgColor: #156f4b;
    --display-pink-bgColor-emphasis: #ce2c85;
    --display-pink-bgColor-muted: #ffe5f1;
    --display-pink-borderColor-emphasis: #ce2c85;
    --display-pink-borderColor-muted: #fdc9e2;
    --display-pink-fgColor: #b12f79;
    --display-plum-bgColor-emphasis: #a830e8;
    --display-plum-bgColor-muted: #f8e5ff;
    --display-plum-borderColor-emphasis: #a830e8;
    --display-plum-borderColor-muted: #f0cdfe;
    --display-plum-fgColor: #961edc;
    --display-purple-bgColor-emphasis: #894ceb;
    --display-purple-bgColor-muted: #f1e5ff;
    --display-purple-borderColor-emphasis: #894ceb;
    --display-purple-borderColor-muted: #e6d2fe;
    --display-purple-fgColor: #783ae4;
    --display-red-bgColor-emphasis: #df0c24;
    --display-red-bgColor-muted: #ffe2e0;
    --display-red-borderColor-emphasis: #df0c24;
    --display-red-borderColor-muted: #fecdcd;
    --display-red-fgColor: #c50d28;
    --display-teal-bgColor-emphasis: #127e81;
    --display-teal-bgColor-muted: #c7f5ef;
    --display-teal-borderColor-emphasis: #127e81;
    --display-teal-borderColor-muted: #89ebe1;
    --display-teal-fgColor: #106e75;
    --display-yellow-bgColor-emphasis: #946a00;
    --display-yellow-bgColor-muted: #ffec9e;
    --display-yellow-borderColor-emphasis: #946a00;
    --display-yellow-borderColor-muted: #ffd642;
    --display-yellow-fgColor: #805900;
    --fgColor-accent: #0969da;
    --fgColor-attention: #9a6700;
    --fgColor-closed: var(--fgColor-danger);
    --fgColor-disabled: #818b98;
    --fgColor-done: #8250df;
    --fgColor-muted: #59636e;
    --fgColor-neutral: #59636e;
    --fgColor-severe: #bc4c00;
    --fgColor-sponsors: #bf3989;
    --fgColor-success: #1a7f37;
    --header-bgColor: #25292e;
    --header-borderColor-divider: #818b98;
    --headerSearch-bgColor: #25292e;
    --headerSearch-borderColor: #818b98;
    --highlight-neutral-bgColor: #fff8c5;
    --label-auburn-bgColor-active: #d4b7b5;
    --label-auburn-bgColor-hover: #e6d6d5;
    --label-auburn-bgColor-rest: #f2e9e9;
    --label-auburn-fgColor-active: #5d3937;
    --label-auburn-fgColor-hover: #744744;
    --label-auburn-fgColor-rest: #8a5551;
    --label-blue-bgColor-active: #75c8ff;
    --label-blue-bgColor-hover: #ade1ff;
    --label-blue-bgColor-rest: #d1f0ff;
    --label-blue-fgColor-active: #003d99;
    --label-blue-fgColor-hover: #004db3;
    --label-blue-fgColor-rest: #005fcc;
    --label-brown-bgColor-active: #cbbda4;
    --label-brown-bgColor-hover: #dfd7c8;
    --label-brown-bgColor-rest: #eeeae2;
    --label-brown-fgColor-active: #51412f;
    --label-brown-fgColor-hover: #64513a;
    --label-brown-fgColor-rest: #755f43;
    --label-coral-bgColor-active: #fcab92;
    --label-coral-bgColor-hover: #fecebe;
    --label-coral-bgColor-rest: #ffe5db;
    --label-coral-fgColor-active: #7e2011;
    --label-coral-fgColor-hover: #9b2712;
    --label-coral-fgColor-rest: #ba2e12;
    --label-cyan-bgColor-active: #00d0fa;
    --label-cyan-bgColor-hover: #7ae9ff;
    --label-cyan-bgColor-rest: #bdf4ff;
    --label-cyan-fgColor-active: #004857;
    --label-cyan-fgColor-hover: #00596b;
    --label-cyan-fgColor-rest: #006a80;
    --label-gray-bgColor-active: #b4c0cf;
    --label-gray-bgColor-hover: #d2dae4;
    --label-gray-bgColor-rest: #e8ecf2;
    --label-gray-fgColor-active: #424448;
    --label-gray-fgColor-hover: #4e535a;
    --label-gray-fgColor-rest: #5c6570;
    --label-green-bgColor-active: #54d961;
    --label-green-bgColor-hover: #9ceda0;
    --label-green-bgColor-rest: #caf7ca;
    --label-green-fgColor-active: #254b34;
    --label-green-fgColor-hover: #285c3b;
    --label-green-fgColor-rest: #2b6e3f;
    --label-indigo-bgColor-active: #b1b9fb;
    --label-indigo-bgColor-hover: #d2d7fe;
    --label-indigo-bgColor-rest: #e5e9ff;
    --label-indigo-fgColor-active: #2d2db4;
    --label-indigo-fgColor-hover: #393cd5;
    --label-indigo-fgColor-rest: #494edf;
    --label-lemon-bgColor-active: #d8bd0e;
    --label-lemon-bgColor-hover: #f0db3d;
    --label-lemon-bgColor-rest: #f7eea1;
    --label-lemon-fgColor-active: #523f00;
    --label-lemon-fgColor-hover: #654f01;
    --label-lemon-fgColor-rest: #786002;
    --label-lime-bgColor-active: #9bd039;
    --label-lime-bgColor-hover: #c7e580;
    --label-lime-bgColor-rest: #e3f2b5;
    --label-lime-fgColor-active: #2f4a21;
    --label-lime-fgColor-hover: #3a5b25;
    --label-lime-fgColor-rest: #476c28;
    --label-olive-bgColor-active: #b9c832;
    --label-olive-bgColor-hover: #dbe170;
    --label-olive-bgColor-rest: #f0f0ad;
    --label-olive-fgColor-active: #3b4927;
    --label-olive-fgColor-hover: #495a2b;
    --label-olive-fgColor-rest: #56682c;
    --label-orange-bgColor-active: #fbaf74;
    --label-orange-bgColor-hover: #fecfaa;
    --label-orange-bgColor-rest: #ffe7d1;
    --label-orange-fgColor-active: #70300f;
    --label-orange-fgColor-hover: #8d3c11;
    --label-orange-fgColor-rest: #a24610;
    --label-pine-bgColor-active: #1dd781;
    --label-pine-bgColor-hover: #80efb9;
    --label-pine-bgColor-rest: #bff8db;
    --label-pine-fgColor-active: #114b36;
    --label-pine-fgColor-hover: #135d41;
    --label-pine-fgColor-rest: #156f4b;
    --label-pink-bgColor-active: #f8a5cf;
    --label-pink-bgColor-hover: #fdc9e2;
    --label-pink-bgColor-rest: #ffe5f1;
    --label-pink-fgColor-active: #6e2b53;
    --label-pink-fgColor-hover: #8e2e66;
    --label-pink-fgColor-rest: #b12f79;
    --label-plum-bgColor-active: #e2a7fb;
    --label-plum-bgColor-hover: #f0cdfe;
    --label-plum-bgColor-rest: #f8e5ff;
    --label-plum-fgColor-active: #651d96;
    --label-plum-fgColor-hover: #7d1eb8;
    --label-plum-fgColor-rest: #961edc;
    --label-purple-bgColor-active: #d1b1fc;
    --label-purple-bgColor-hover: #e6d2fe;
    --label-purple-bgColor-rest: #f1e5ff;
    --label-purple-fgColor-active: #4f21ab;
    --label-purple-fgColor-hover: #6223d7;
    --label-purple-fgColor-rest: #783ae4;
    --label-red-bgColor-active: #fda5a7;
    --label-red-bgColor-hover: #fecdcd;
    --label-red-bgColor-rest: #ffe2e0;
    --label-red-fgColor-active: #880c27;
    --label-red-fgColor-hover: #a60c29;
    --label-red-fgColor-rest: #c50d28;
    --label-teal-bgColor-active: #22d3c7;
    --label-teal-bgColor-hover: #89ebe1;
    --label-teal-bgColor-rest: #c7f5ef;
    --label-teal-fgColor-active: #0a4852;
    --label-teal-fgColor-hover: #0d5b63;
    --label-teal-fgColor-rest: #106e75;
    --label-yellow-bgColor-active: #ebb400;
    --label-yellow-bgColor-hover: #ffd642;
    --label-yellow-bgColor-rest: #ffec9e;
    --label-yellow-fgColor-active: #5c3d00;
    --label-yellow-fgColor-hover: #704d00;
    --label-yellow-fgColor-rest: #805900;
    --menu-bgColor-active: #ffffff00;
    --overlay-backdrop-bgColor: #c8d1da66;
    --reactionButton-selected-bgColor-rest: #ddf4ff;
    --reactionButton-selected-fgColor-hover: #0550ae;
    --selectMenu-bgColor-active: #b6e3ff;
    --selectMenu-borderColor: #ffffff00;
    --shadow-floating-legacy: 0px 6px 12px -3px #25292e0a, 0px 6px 18px 0px #25292e1f;
    --shadow-resting-medium: 0px 1px 1px 0px #25292e1a, 0px 3px 6px 0px #25292e1f;
    --skeletonLoader-bgColor: #818b981a;
    --topicTag-borderColor: #ffffff00;
    --treeViewItem-leadingVisual-iconColor-rest: #54aeff;
    --underlineNav-borderColor-active: #fd8c73;
    --avatar-bgColor: #ffffff;
    --avatar-shadow: 0px 0px 0px 2px #ffffffcc;
    --bgColor-black: #1f2328;
    --bgColor-closed-emphasis: var(--bgColor-danger-emphasis);
    --bgColor-closed-muted: var(--bgColor-danger-muted);
    --bgColor-default: #ffffff;
    --bgColor-inset: var(--bgColor-muted);
    --bgColor-open-muted: var(--bgColor-success-muted);
    --bgColor-upsell-emphasis: var(--bgColor-done-emphasis);
    --bgColor-upsell-muted: var(--bgColor-done-muted);
    --bgColor-white: #ffffff;
    --border-accent-emphasis: 0.0625rem solid #0969da;
    --border-accent-muted: 0.0625rem solid #54aeff66;
    --border-attention-emphasis: 0.0625rem solid #9a6700;
    --border-attention-muted: 0.0625rem solid #d4a72c66;
    --border-danger-emphasis: 0.0625rem solid #cf222e;
    --border-danger-muted: 0.0625rem solid #ff818266;
    --border-default: 0.0625rem solid #d1d9e0;
    --border-disabled: 0.0625rem solid #818b981a;
    --border-done-emphasis: 0.0625rem solid #8250df;
    --border-done-muted: 0.0625rem solid #c297ff66;
    --border-emphasis: 0.0625rem solid #818b98;
    --border-neutral-emphasis: 0.0625rem solid #59636e;
    --border-severe-emphasis: 0.0625rem solid #bc4c00;
    --border-severe-muted: 0.0625rem solid #fb8f4466;
    --border-sponsors-emphasis: 0.0625rem solid #bf3989;
    --border-sponsors-muted: 0.0625rem solid #ff80c866;
    --border-success-emphasis: 0.0625rem solid #1a7f37;
    --border-success-muted: 0.0625rem solid #4ac26b66;
    --border-transparent: 0.0625rem solid #ffffff00;
    --borderColor-closed-emphasis: var(--borderColor-danger-emphasis);
    --borderColor-closed-muted: var(--borderColor-danger-muted);
    --borderColor-muted: #d1d9e0b3;
    --borderColor-open-emphasis: var(--borderColor-success-emphasis);
    --borderColor-open-muted: var(--borderColor-success-muted);
    --borderColor-translucent: #1f232826;
    --borderColor-upsell-emphasis: var(--borderColor-done-emphasis);
    --borderColor-upsell-muted: var(--borderColor-done-muted);
    --button-danger-bgColor-rest: var(--control-bgColor-rest);
    --button-danger-fgColor-active: #ffffff;
    --button-danger-fgColor-hover: #ffffff;
    --button-danger-iconColor-hover: #ffffff;
    --button-default-bgColor-active: var(--control-bgColor-active);
    --button-default-bgColor-hover: var(--control-bgColor-hover);
    --button-default-bgColor-rest: var(--control-bgColor-rest);
    --button-default-bgColor-selected: var(--control-bgColor-active);
    --button-default-fgColor-rest: var(--control-fgColor-rest);
    --button-default-shadow-resting: 0px 1px 0px 0px #1f23280a;
    --button-invisible-bgColor-active: var(--control-transparent-bgColor-active);
    --button-invisible-bgColor-hover: var(--control-transparent-bgColor-hover);
    --button-invisible-bgColor-rest: var(--control-transparent-bgColor-rest);
    --button-invisible-borderColor-hover: var(--control-transparent-borderColor-hover);
    --button-invisible-borderColor-rest: var(--control-transparent-borderColor-rest);
    --button-invisible-fgColor-active: var(--control-fgColor-rest);
    --button-invisible-fgColor-hover: var(--control-fgColor-rest);
    --button-invisible-fgColor-rest: var(--control-fgColor-rest);
    --button-invisible-iconColor-hover: var(--fgColor-muted);
    --button-invisible-iconColor-rest: var(--fgColor-muted);
    --button-outline-bgColor-hover: var(--bgColor-accent-emphasis);
    --button-outline-bgColor-rest: var(--control-bgColor-rest);
    --button-outline-fgColor-active: #ffffff;
    --button-outline-fgColor-disabled: #0969da80;
    --button-outline-fgColor-hover: #ffffff;
    --button-outline-fgColor-rest: var(--fgColor-accent);
    --button-primary-borderColor-rest: #1f232826;
    --button-primary-fgColor-disabled: #ffffffcc;
    --buttonCounter-danger-bgColor-disabled: #cf222e0d;
    --buttonCounter-danger-bgColor-hover: #ffffff33;
    --buttonCounter-danger-bgColor-rest: #cf222e1a;
    --buttonCounter-danger-fgColor-hover: #ffffff;
    --buttonCounter-default-bgColor-rest: var(--bgColor-neutral-muted);
    --buttonCounter-invisible-bgColor-rest: var(--bgColor-neutral-muted);
    --buttonCounter-outline-bgColor-disabled: #0969da0d;
    --buttonCounter-outline-bgColor-hover: #ffffff33;
    --buttonCounter-outline-bgColor-rest: #0969da1a;
    --buttonCounter-outline-fgColor-disabled: #0969da80;
    --buttonCounter-outline-fgColor-hover: #ffffff;
    --codeMirror-activeline-bgColor: var(--bgColor-neutral-muted);
    --codeMirror-gutterMarker-fgColor-muted: var(--fgColor-muted);
    --codeMirror-lineNumber-fgColor: var(--fgColor-muted);
    --codeMirror-selection-bgColor: var(--borderColor-accent-muted);
    --codeMirror-syntax-fgColor-comment: #1f2328;
    --color-ansi-black: #1f2328;
    --color-prettylights-syntax-markup-bold: #1f2328;
    --color-prettylights-syntax-markup-italic: #1f2328;
    --color-prettylights-syntax-storage-modifier-import: #1f2328;
    --control-bgColor-disabled: var(--bgColor-disabled);
    --control-bgColor-selected: var(--control-bgColor-rest);
    --control-borderColor-danger: var(--borderColor-danger-emphasis);
    --control-borderColor-disabled: var(--borderColor-disabled);
    --control-borderColor-emphasis: var(--borderColor-emphasis);
    --control-borderColor-rest: var(--borderColor-default);
    --control-borderColor-success: var(--borderColor-success-emphasis);
    --control-borderColor-warning: var(--borderColor-attention-emphasis);
    --control-checked-bgColor-disabled: var(--fgColor-disabled);
    --control-checked-bgColor-rest: var(--bgColor-accent-emphasis);
    --control-danger-bgColor-active: #ffebe966;
    --control-danger-bgColor-hover: var(--bgColor-danger-muted);
    --control-fgColor-disabled: var(--fgColor-disabled);
    --control-fgColor-placeholder: var(--fgColor-muted);
    --control-iconColor-rest: var(--fgColor-muted);
    --control-transparent-bgColor-disabled: var(--bgColor-disabled);
    --controlKnob-bgColor-checked: #ffffff;
    --controlKnob-bgColor-rest: #ffffff;
    --controlTrack-bgColor-disabled: var(--fgColor-disabled);
    --controlTrack-borderColor-disabled: var(--fgColor-disabled);
    --controlTrack-borderColor-rest: var(--borderColor-default);
    --counter-bgColor-emphasis: var(--bgColor-neutral-emphasis);
    --counter-bgColor-muted: var(--bgColor-neutral-muted);
    --data-auburn-color-gradientStop-1: var(--data-auburn-color-muted);
    --data-auburn-color-gradientStop-2: #f2e9e900;
    --data-blue-color-gradientStop-1: var(--data-blue-color-muted);
    --data-blue-color-gradientStop-2: #d1f0ff00;
    --data-brown-color-gradientStop-1: var(--data-brown-color-muted);
    --data-brown-color-gradientStop-2: #eeeae200;
    --data-coral-color-gradientStop-1: var(--data-coral-color-muted);
    --data-coral-color-gradientStop-2: #ffe5db00;
    --data-gray-color-gradientStop-1: var(--data-gray-color-muted);
    --data-gray-color-gradientStop-2: #e8ecf200;
    --data-green-color-gradientStop-1: var(--data-green-color-muted);
    --data-green-color-gradientStop-2: #caf7ca00;
    --data-lemon-color-gradientStop-1: var(--data-lemon-color-muted);
    --data-lemon-color-gradientStop-2: #f7eea100;
    --data-lime-color-gradientStop-1: var(--data-lime-color-muted);
    --data-lime-color-gradientStop-2: #e3f2b500;
    --data-olive-color-gradientStop-1: var(--data-olive-color-muted);
    --data-olive-color-gradientStop-2: #f0f0ad00;
    --data-orange-color-gradientStop-1: var(--data-orange-color-muted);
    --data-orange-color-gradientStop-2: #ffe7d100;
    --data-pine-color-gradientStop-1: var(--data-pine-color-muted);
    --data-pine-color-gradientStop-2: #bff8db00;
    --data-pink-color-gradientStop-1: var(--data-pink-color-muted);
    --data-pink-color-gradientStop-2: #ffe5f100;
    --data-plum-color-gradientStop-1: var(--data-plum-color-muted);
    --data-plum-color-gradientStop-2: #f8e5ff00;
    --data-purple-color-gradientStop-1: var(--data-purple-color-muted);
    --data-purple-color-gradientStop-2: #f1e5ff00;
    --data-red-color-gradientStop-1: var(--data-red-color-muted);
    --data-red-color-gradientStop-2: #ffe2e000;
    --data-teal-color-gradientStop-1: var(--data-teal-color-muted);
    --data-teal-color-gradientStop-2: #c7f5ef00;
    --data-yellow-color-gradientStop-1: var(--data-yellow-color-muted);
    --data-yellow-color-gradientStop-2: #ffec9e00;
    --diffBlob-additionLine-bgColor: var(--bgColor-success-muted);
    --diffBlob-deletionLine-bgColor: var(--bgColor-danger-muted);
    --diffBlob-emptyLine-bgColor: var(--bgColor-muted);
    --diffBlob-emptyNum-bgColor: var(--bgColor-muted);
    --diffBlob-expander-iconColor: var(--fgColor-muted);
    --diffBlob-hunkLine-bgColor: var(--bgColor-accent-muted);
    --diffBlob-hunkLine-fgColor: var(--fgColor-muted);
    --diffBlob-hunkNum-bgColor-hover: var(--bgColor-accent-emphasis);
    --fgColor-black: #1f2328;
    --fgColor-default: #1f2328;
    --fgColor-link: var(--fgColor-accent);
    --fgColor-onEmphasis: #ffffff;
    --fgColor-onInverse: #ffffff;
    --fgColor-open: var(--fgColor-success);
    --fgColor-upsell: var(--fgColor-done);
    --fgColor-white: #ffffff;
    --focus-outlineColor: var(--borderColor-accent-emphasis);
    --header-fgColor-default: #ffffffb3;
    --header-fgColor-logo: #ffffff;
    --overlay-bgColor: #ffffff;
    --page-header-bgColor: var(--bgColor-muted);
    --selection-bgColor: #0969da33;
    --shadow-inset: inset 0px 1px 0px 0px #1f23280a;
    --shadow-resting-small: 0px 1px 1px 0px #1f23280f, 0px 1px 3px 0px #1f23280f;
    --shadow-resting-xsmall: 0px 1px 1px 0px #1f23280f;
    --sideNav-bgColor-selected: #ffffff;
    --timelineBadge-bgColor: var(--bgColor-muted);
    --tooltip-bgColor: var(--bgColor-emphasis);
    --underlineNav-iconColor-rest: var(--fgColor-muted);
    --avatar-borderColor: var(--borderColor-translucent);
    --border-closed-emphasis: var(--border-danger-emphasis);
    --border-closed-muted: var(--border-danger-muted);
    --border-muted: 0.0625rem solid #d1d9e0b3;
    --border-open-emphasis: var(--border-success-emphasis);
    --border-open-muted: var(--border-success-muted);
    --border-upsell-emphasis: 0.0625rem solid #8250df;
    --border-upsell-muted: 0.0625rem solid #c297ff66;
    --borderColor-neutral-muted: var(--borderColor-muted);
    --button-danger-bgColor-disabled: var(--control-bgColor-disabled);
    --button-danger-borderColor-hover: var(--button-primary-borderColor-rest);
    --button-danger-borderColor-rest: var(--control-borderColor-rest);
    --button-default-bgColor-disabled: var(--control-bgColor-disabled);
    --button-default-borderColor-active: var(--control-borderColor-rest);
    --button-default-borderColor-disabled: var(--control-borderColor-disabled);
    --button-default-borderColor-rest: var(--control-borderColor-rest);
    --button-invisible-fgColor-disabled: var(--control-fgColor-disabled);
    --button-invisible-iconColor-disabled: var(--control-fgColor-disabled);
    --button-outline-bgColor-disabled: var(--control-bgColor-disabled);
    --button-primary-borderColor-active: var(--button-primary-borderColor-rest);
    --button-primary-borderColor-hover: var(--button-primary-borderColor-rest);
    --button-primary-fgColor-rest: var(--fgColor-white);
    --button-primary-iconColor-rest: #ffffffcc;
    --card-bgColor: var(--bgColor-default);
    --codeMirror-bgColor: var(--bgColor-default);
    --codeMirror-cursor-fgColor: var(--fgColor-default);
    --codeMirror-fgColor: var(--fgColor-default);
    --codeMirror-gutterMarker-fgColor-default: var(--bgColor-default);
    --codeMirror-gutters-bgColor: var(--bgColor-default);
    --codeMirror-lines-bgColor: var(--bgColor-default);
    --codeMirror-matchingBracket-fgColor: var(--fgColor-default);
    --control-borderColor-selected: var(--control-bgColor-selected);
    --control-checked-borderColor-disabled: var(--control-checked-bgColor-disabled);
    --control-checked-borderColor-rest: var(--control-checked-bgColor-rest);
    --control-checked-fgColor-disabled: var(--fgColor-onEmphasis);
    --control-checked-fgColor-rest: var(--fgColor-onEmphasis);
    --controlKnob-bgColor-disabled: var(--control-bgColor-disabled);
    --controlKnob-borderColor-checked: var(--control-checked-bgColor-rest);
    --controlKnob-borderColor-disabled: var(--control-bgColor-disabled);
    --controlKnob-borderColor-rest: var(--control-borderColor-emphasis);
    --controlTrack-fgColor-disabled: var(--fgColor-onEmphasis);
    --data-auburn-gradient: linear-gradient(180deg, #f2e9e9 0%, #f2e9e900 100%);
    --data-blue-gradient: linear-gradient(180deg, #d1f0ff 0%, #d1f0ff00 100%);
    --data-brown-gradient: linear-gradient(180deg, #eeeae2 0%, #eeeae200 100%);
    --data-coral-gradient: linear-gradient(180deg, #ffe5db 0%, #ffe5db00 100%);
    --data-gray-gradient: linear-gradient(180deg, #e8ecf2 0%, #e8ecf200 100%);
    --data-green-gradient: linear-gradient(180deg, #caf7ca 0%, #caf7ca00 100%);
    --data-lemon-gradient: linear-gradient(180deg, #f7eea1 0%, #f7eea100 100%);
    --data-lime-gradient: linear-gradient(180deg, #e3f2b5 0%, #e3f2b500 100%);
    --data-olive-gradient: linear-gradient(180deg, #f0f0ad 0%, #f0f0ad00 100%);
    --data-orange-gradient: linear-gradient(180deg, #ffe7d1 0%, #ffe7d100 100%);
    --data-pine-gradient: linear-gradient(180deg, #bff8db 0%, #bff8db00 100%);
    --data-pink-gradient: linear-gradient(180deg, #ffe5f1 0%, #ffe5f100 100%);
    --data-plum-gradient: linear-gradient(180deg, #f8e5ff 0%, #f8e5ff00 100%);
    --data-purple-gradient: linear-gradient(180deg, #f1e5ff 0%, #f1e5ff00 100%);
    --data-red-gradient: linear-gradient(180deg, #ffe2e0 0%, #ffe2e000 100%);
    --data-teal-gradient: linear-gradient(180deg, #c7f5ef 0%, #c7f5ef00 100%);
    --data-yellow-gradient: linear-gradient(180deg, #ffec9e 0%, #ffec9e00 100%);
    --diffBlob-additionLine-fgColor: var(--fgColor-default);
    --diffBlob-additionNum-fgColor: var(--fgColor-default);
    --diffBlob-additionWord-fgColor: var(--fgColor-default);
    --diffBlob-deletionLine-fgColor: var(--fgColor-default);
    --diffBlob-deletionNum-fgColor: var(--fgColor-default);
    --diffBlob-deletionWord-fgColor: var(--fgColor-default);
    --diffBlob-hunkNum-fgColor-hover: var(--fgColor-onEmphasis);
    --diffBlob-hunkNum-fgColor-rest: var(--fgColor-default);
    --focus-outline: 2px solid #0969da;
    --overlay-borderColor: #d1d9e080;
    --reactionButton-selected-fgColor-rest: var(--fgColor-link);
    --tooltip-fgColor: var(--fgColor-onEmphasis);
    --underlineNav-borderColor-hover: var(--borderColor-muted);
    --border-neutral-muted: 0.0625rem solid #d1d9e0b3;
    --button-danger-borderColor-active: var(--button-danger-borderColor-hover);
    --button-default-borderColor-hover: var(--button-default-borderColor-rest);
    --button-outline-borderColor-hover: var(--button-primary-borderColor-hover);
    --shadow-floating-large: 0px 0px 0px 1px #d1d9e0, 0px 40px 80px 0px #25292e3d;
    --shadow-floating-medium: 0px 0px 0px 1px #d1d9e0, 0px 8px 16px -4px #25292e14, 0px 4px 32px -4px #25292e14, 0px 24px 48px -12px #25292e14, 0px 48px 96px -24px #25292e14;
    --shadow-floating-small: 0px 0px 0px 1px #d1d9e080, 0px 6px 12px -3px #25292e0a, 0px 6px 18px 0px #25292e1f;
    --shadow-floating-xlarge: 0px 0px 0px 1px #d1d9e0, 0px 56px 112px 0px #25292e52;
    --button-outline-borderColor-active: var(--button-outline-borderColor-hover);
  }
}
